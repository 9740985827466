import { IUser, IWixAPI } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID } from '../constants';
import { retry } from './retry';

export const createOnLoginHandlerWithTB8465Workaround = (wixCodeApi: IWixAPI) => (onLogin: (user: IUser) => void) => {
  let p1Resolve: () => void, p2Resolve: () => void;
  const p1 = new Promise<void>((resolve) => (p1Resolve = resolve));
  const p2 = new Promise<void>((resolve) => (p2Resolve = resolve));

  wixCodeApi.site.onInstanceChanged(() => p1Resolve(), PRICING_PLANS_APP_DEF_ID);
  wixCodeApi.user.onLogin(() => p2Resolve());

  Promise.all([p1, p2]).then(() => onLogin(wixCodeApi.user.currentUser));
};

export const TB8465_createOnLoginHandlerWithRetry = (wixCodeApi: IWixAPI, onLogin: (user: IUser) => void) => {
  wixCodeApi.user.onLogin(() =>
    retry({ times: 5, delay: 100 }, async () => {
      const token = wixCodeApi.site.getAppToken?.(PRICING_PLANS_APP_DEF_ID);
      if (token && parseAppToken(token).uid) {
        onLogin(wixCodeApi.user.currentUser);
      } else {
        throw new Error('wixCodeApi.user.onLogin: not logged in yet.');
      }
    }),
  );
};

function parseAppToken(appToken: string) {
  return JSON.parse(atob(appToken.split('.')[1]));
}
